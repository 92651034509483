<template>
    <div id="institucional" class="h-100">
        <Headerbar/>
        <b-container id="url_custom" fluid>
            <b-row>
                <b-col>
                    <div id="revendedor-marcas">

                        <h2>SELECIONE A MARCA</h2>
                        <!--                        <h3>{{ reseller.NomeRevendedora }}</h3>-->

                        <!-- Listar Marcas -->
                        <b-overlay :show="showLoading" rounded="sm">
                            <b-list-group class="justify-content-center">
                                <b-list-group-item v-for="catalog in catalogs" v-bind:key="catalog.VitrineID">
                                    <router-link target="_blank" :to="`/vitrine/${ catalog.VitrineID }`">
                                        <b-button type="submit" variant="primary" block>
                                            <b-img-lazy fluid alt="ZZVendas" :src=" getLogoFix( catalog.MarcaIdo ) "></b-img-lazy>
                                        </b-button>
                                    </router-link>
                                </b-list-group-item>
                            </b-list-group>
                        </b-overlay>

                    </div>
                </b-col>
            </b-row>
            <b-row class="rodape" style="padding-top: 20px">
                <b-col class="text-right">
                    <b-img-lazy style="height: 20px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/anacapri-branco.png') "
                    ></b-img-lazy>
                </b-col>
                <b-col class="text-left d-flex align-items-center">
                    <b-img-lazy style="height: 15px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/arezzo-branco.png') "
                    ></b-img-lazy>
                </b-col>
            </b-row>
            <b-row class="rodape" style="padding-bottom: 20px">
                <b-col class="text-center">
                    Todos os direitos reservados Arezzo&Co
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<style lang="scss">
#url_custom
{
    background-color: #E9E9E9;
    display: grid;
    grid-template-rows: auto 50px 50px;

    #revendedor-marcas
    {
        border-radius: 38px;
        background-color: #F9F9F9;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 95%;
        display: grid;

        h2
        {
            text-align: center;
            font-size: 14px;
            margin: 20px 0 0;
            font-weight: bold;
            color: #707070;
            font-family: "Montserrat-SemiBold", Arial, Helvetica, sans-serif !important;
        }

        .b-overlay-wrap
        {
            display: flex;
            justify-content: center;

            .list-group-item
            {
                background-color: transparent;
                border: none;
                display: flex;
                justify-content: center;

                button
                {
                    background-color: #1EBCD5;
                    border: 0;
                    border-radius: 22px;
                    color: white;
                    font-weight: bold;
                    font-size: 16px;
                    width: 250px;
                    box-shadow: 0 3px 5px #00000085;
                    height: 60px;

                    .img-fluid
                    {
                        width: 40%;
                    }
                }
            }
        }

    }

    .rodape
    {
        background-color: #1EBCD5;
        color: white;
        font-size: 10px;
    }
}

@media (max-width: 767px)
{
    #url_custom
    {
        #revendedor-marcas
        {
            padding: 0;
            margin: 30px 5px 0;
            height: 90%;
            grid-template-rows: 50px auto;
        }
    }
}

@media (min-width: 768px)
{
    #url_custom
    {
        h2
        {
            display: none;
        }

        .row:first-of-type .col
        {
            display: grid;
            align-items: center;
            justify-content: center;
        }

        #revendedor-marcas
        {
            width: 800px;
            height: 600px;
            grid-template-rows: auto;
        }
    }
}
</style>
<script>
import Vue from 'vue'
import store from '@/store'
import {Utils} from '@/components/utils'

import '../../assets/css/institucional.scss'
import Headerbar from '../../components/site_institucional/headerbar'

export default {
    name: 'url_custom',
    props: ['url_custom'],
    components: {Headerbar},
    mixins: [Utils],
    data() {
        return {
            showLoading: true,
            reseller: {},
            catalogs: [],
        }
    },
    methods: {
        GetResellerInfo() {
            store.dispatch('GetInfoByNomeLoja', this.url_custom)
                .then((response) => {

                    if (Vue.lodash.isEmpty(response)) {
                        this.$router.push({name: 'page-not-found'})
                    }
                    else {
                        // Identificar vendedor
                        this.reseller = response.Info

                        // Identificar vitrines
                        this.catalogs = response.Vitrines

                        // Caso tenha apenas um registro, direcionar para ele
                        // if ( this.catalogs.length === 1 ) {
                        //     const firstCatalog = Vue.lodash.first( this.catalogs )
                        //     this.$router.push( `/vitrine/${ firstCatalog.VitrineID }/` )
                        // }

                        this.showLoading = false
                    }

                })
                .catch((error) => {
                    console.log('error', error)
                })
        },
        getLogoFix(MarcaIdo) {
            let logo = '';

            switch ( MarcaIdo ) {
              case 1 :
                logo = "arezzo.png";
                break;
              case 3 :
                logo = "alexandre_birman.png";
                break;
              case 4 :
                logo = "anacapri-branco.png";
                break;
              case 6 :
                logo = "fiever-branco.png";
                break;
              case 7 :
                logo = "alme-branco.png";
                break;
              case 11 :
                logo = "vans-branco.png";
                break;
              case "zzvendas":
                logo = "zzvendas.png";
                break;
              default :
                logo = "arezzoco.png";
                break;
            }

            return require('../../assets/logos/'.concat(logo));
        },
        resizeForm() {

            // Ajustar o app para 100%
            document.getElementById('app').style.height = '100%'

            // Calibrar area disponivel
            const windowSize = window.innerHeight
            const headerSize = document.getElementById('header').clientHeight
            document.getElementById('url_custom').style.minHeight = (windowSize - headerSize).toString().concat('px')
        },
    },
    mounted() {
        this.resizeForm()
        this.GetResellerInfo()
    },
}
</script>
